.full-page {
  min-height: 100vh;
  max-width: 100vw;
}

.full-height {
  display: flex;
}

.content {
  padding: $CONTENT_SPACING;
  overflow: auto;
  display: flex;
  flex-direction: column;
}


.content-crud {
  @extend .content;
  background: $COLOR_WHITE;
}

.content-dp {
  padding-left: $CONTENT_DP;
  padding-bottom: $CONTENT_DP;
  padding-right: $CONTENT_DP;
  background: $COLOR_LIGHTER_GREY2;
  width: 99%;
  margin: 0 auto;
}

.master-header {
  height: $CRUD_HEADER_HEIGHT;
  vertical-align: middle;
  margin-bottom: $CONTENT_SPACING;

  h1, a {
    display: inline-block;
    line-height: $CRUD_HEADER_HEIGHT;
  }

  h1 {
    margin-right: $CONTENT_SPACING;
  }

  div {
    height: $CRUD_HEADER_HEIGHT;
  }
}

.overview-top {
  padding-top: $CONTENT_DP;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.detail-section {
  .top-row {
    border-bottom: 1px solid $COLOR_LIGHT_GREY1;
  }
}

.detail-section-section {
  padding: 15px;
  border-right: 1px solid $COLOR_LIGHT_GREY1;
  box-sizing: border-box;

  &.left {
    padding-left: 0;
  }

  &.top {
    height: 63px;
    padding-top: 0;
  }

  &.right {
    padding-right: 0;
    border-right: 0;
    border-right: 0;
  }

  &.bottom {
    height: 85px;
  }

  &.links {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.generic-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;

  .timestamp {
    margin-right: $CONTENT_DP;
  }
}

.generic-section-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.detail-section {
  .top-row {
    border-bottom: 1px solid $COLOR_LIGHT_GREY1;
  }
}

.detail-section-section {
  padding: 15px;
  border-right: 1px solid $COLOR_LIGHT_GREY1;
  box-sizing: border-box;

  &.left {
    padding-left: 0;
  }

  &.top {
    height: 63px;
    padding-top: 0;
  }

  &.right {
    padding-right: 0;
    border-right: 0;
    border-right: 0;
  }

  &.right-side {
    display: flex;
    justify-content: flex-end;
  }

  &.bottom {
    height: 85px;
  }

  &.links {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  & .idletime {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.generic-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;

  .timestamp {
    margin-right: $CONTENT_DP;
  }
}

.generic-section-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.links-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  .visit-top-link:first-child {
    padding-left: 0;
  }

  .visit-top-link {
    color: $COLOR_BLACK;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & span {
      height: 100%;
  }

    line-height: 1.5;
  }

  .visit-top-link:hover {
    transition: color .3s;
    color: $COLOR_ORANGE;
    cursor: pointer;
    outline: none;
    text-decoration: none;
  }
}

#empties-yard-section {
  h3 {
    margin-top: $COLLAPSE_SPACING;
    margin-bottom: $COLLAPSE_SPACING;
  }
}

.detail-row {
  margin-top: $COLLAPSE_SPACING;
  display: block;
  width: 100%;

  h3 {
    margin-bottom: 0;
  }

  &.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}

.detail-row-bollard {
  margin-top: $COLLAPSE_SPACING;

  h3 {
    margin-bottom: 0;
  }

  &.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}

.detail-row-panel {
  margin-top: $CONTENT_DP;
}

.navchart-wrapper {
  width: 100%;
}

.ant-layout-block {
  background: $COLOR_LIGHTER_GREY2;
  display: block;
}

.ant-layout.ant-layout-has-sider {
  display: flex;
}

.ant-layout-header .top-menu .ant-menu-horizontal {
  height: 64px;
}
.quay-cell-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .quay-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &-section {
      color: $COLOR_LIGHTER_GREY3;
    }
  }
}

.visit-summary {
  display: flex;
  justify-content: flex-start;

  strong, span {
    margin-bottom: $CONTENT_DP;
  }
}

.gang-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  margin-top: 8px;
  margin-bottom: 8px;

  .gang-row-form {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
  }

  .gang-row-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    .ant-btn, .ant-btn[disabled] {
      height: fit-content;
      width: fit-content;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-inline: 2px;
    }
  }
}

.actionlog-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 2px;
}

.no-info-available {
  background: $COLOR_WHITE;
  text-align: center;
  padding: 50px 0 50px 0;
  border: 1px solid $COLOR_LIGHT_GREY2;
  font-size: $SIZE_XL;
  color: $COLOR_LIGHTER_GREY3;
  font-weight: 100;
  font-style: italic;

  p {
    margin: 0;
  }
}

.table-centric {
  .ant-table-row {
    text-align: center;
  }
}
