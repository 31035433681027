@import "constants.scss";

@import "alert";
@import "button";
@import "collapse";
@import "contactInfo";
@import "elearning";
@import "form";
@import "label";
@import "lastUpdated";
@import "layout";
@import "link";
@import "map";
@import "menu.scss";
@import "modal";
@import "navigation-chart";
@import "panel";
@import "remark";
@import "switch";
@import "table.scss";
@import "tag";
@import "text";
@import "utils";
@import "visit";
@import "visits.scss";
@import "widgets";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,100i');

body {
  font-family: "Roboto", sans-serif;
  color: $COLOR_TEXT;

  h1 {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 0;
  }

  h2 {
    color: #999999;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

@-moz-document url-prefix() {
  .logo {
    margin-top: -1px;
  }
}

.logo img {
    height: 23px;
    margin: 15px 5px 15px 10px;
}

//// ICON

.icon-downcurved {
  transform: rotate(90deg);
}

//// INPUT
.ant-input, .ant-select-selection, .ant-select-dropdown-menu {
  background: $COLOR_WHITE;
  color: $COLOR_GREY;
}

.ant-form-item-required:before {
  color: $COLOR_TEXT;
}

.ant-select {
  min-width: 120px;
}

.title-subtitle {
  height: 50px;
}

.title-subtitle, .subtitle {
  align-items: center;
  display: flex;
}

.title-subtitle-wrapper {
  width: 100%;

  h3 {
    display: inline-block;
  }
}

.unauthorized-wrapper {
  margin-top: 120px;

  .unauthorized-icon {
    font-size: 200px;
    opacity: 0.32;
  }

  .unauthorized-title {
    margin-top: 60px;
    font-weight: 500;
    color: $COLOR_GREY;
  }
}

.main-wrapper-login {
  background: $COLOR_WHITE;
}

.login-page {
  margin-top: 60px;
  padding-top: 60px;

  h2 {
    margin-top: 20px;
  }

  .login-button-wrapper {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.no-empty-image-ant {
  .ant-empty-image {
    display: none;
  }
}

.no-empty-text-ant {
  .ant-empty-description {
    display: none;
  }
}
