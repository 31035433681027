.progress-tracker {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-radius: 10px;
  align-items: center;

  &.progress-tracker-normal {
    background-color: $COLOR_LIGHT_GREY1;
    .ant-progress-bg{
      background-color: $COLOR_WHITE;
    }
    .progress-tracker-text {
      color: $COLOR_MENU;
    }
  }

  &.progress-tracker-active {
    background-color: $COLOR_LIGHT_BLUE;
    .ant-progress-bg{
      background-color: $COLOR_BLUE;
    }
    .progress-tracker-text {
      color: $COLOR_BLUE;
    }
  }

  &.progress-tracker-success {
    background-color: rgba(49, 173, 79, 0.15);
    .ant-progress-bg{
      background-color: $COLOR_GREEN;
    }
    .progress-tracker-text {
      color: $COLOR_DARK_GREEN;
    }
  }

  .progress-tracker-text {
    display: inline-block;
    margin-right: 15px;
    white-space: nowrap;
  }

  .ant-progress-inner {
    background-color: $COLOR_WHITE;
  }
}

.counter-body {
  h4 {
    color: $COLOR_DUST_GREY;
    font-size: 12px;
    margin-top: 18px;
  }
}

.shift-quay-combinator {
  margin-bottom: 10px;
}

.no-saldo-message {
  margin-top: 27px;
  color: $COLOR_DUST_GREY;
}
