.ant-btn-default {
  display: inline-block;
  color: $COLOR_TEXT;
  height: 42px;
  border: 1px solid #E1E5EB;
  border-radius: 2px;
  background-color: $COLOR_WHITE;
  padding: 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  box-shadow: 0 2px 1px 0 rgba(85,85,85,0.15);

  &:disabled {
    box-shadow: 0 2px 1px 0 rgba(85, 85, 85, 0.15);
  }

  &.ant-btn-sm {
    height: 28px;
  }
}

.ant-btn-dashed {
  border-radius: 25px;
}

.cancel {
  margin-left: 30px;
  color: $COLOR_TEXT;
  text-decoration: underline;
}

.ant-popover-buttons {
  display: flex;
  align-items: center;
}
