@import "constants.scss";

.ant-table-thead > tr.ant-table-row-hover > td,
.ant-table-tbody > tr.ant-table-row-hover > td,
.ant-table-thead > tr:hover > td,
.ant-table-tbody > tr:hover > td {
  background: $COLOR_LIGHTER_GREY1;
  cursor: pointer;
}

.ant-table-bordered .ant-table-thead > tr > th > span {
  color: $COLOR_GREY;
  font-weight: normal;
}

.ant-table-bordered .ant-table-thead > tr:first-child > th > span {
  color: $COLOR_MENU;
  font-weight: 500;
}

.ant-table-thead > tr > th.ant-table-column-has-filters {
  text-align: center;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background: $COLOR_LIGHTER_GREY1;
}

.ant-table-body {
  background: $COLOR_WHITE;
}

.ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid $COLOR_LIGHT_GREY2;
}

.action-bar-table-header {
  margin-bottom: 20px;
  padding-left: 26px;

  a[disabled] {
    color: $COLOR_DUST_GREY;
    text-decoration: none;
  }
}

.lpl-action-bar-table-header {
  padding-left: 76px;
}

.ant-table-small.ant-table-bordered .ant-table-content,
.ant-table-small.ant-table-bordered .ant-table-body {
  border-right: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-bottom-width: 1px;
}

.ant-table-small {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.table-hoverless {
  margin-bottom: $CONTENT_SPACING;

  .ant-table-title {
    top: 0;
    padding: 0;
    padding-bottom: $CONTENT_SPACING / 2;

    h2 {
      display: inline-block;
    }
  }
}

.table-hoverless-row {
  pointer-events: none;

  td {
    padding-left: 0 !important;
  }
}

.table-hoverless-row > td > span {
  pointer-events: all;
  cursor: text;
}

.table-hoverless-row:hover,
.table-hoverless-row:hover > td {
  background: none !important;
}

.tal-table {
  .ant-table-tbody > tr:hover > td {
    cursor: auto;
  }
}

.custom-filter-range {
  padding: 15px;
}

.custom-filter-range, .custom-filter-dropdown {
  border-radius: 6px;
  background: $COLOR_WHITE;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);

  .ant-form-inline .ant-form-item {
    margin-right: 0;
  }
}

.custom-filter-header {
  padding: 15px;
}

.custom-filter-content {
  padding: 0 15px;
  overflow-y: scroll;
  max-height: 300px;
}

.custom-filter-row {
  padding: 8px 0;
}

.custom-filter-footer {
  padding: 15px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-bottom: 5px;
    width: 100%;
  }
}

.ant-table-thead > tr > th .anticon-filter.filtered {
  color: $COLOR_PRIMARY;
}

.editable-table {
  .ant-table-tbody > tr:hover > td {
    cursor: auto;
  }

  .editable-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 4px;
    padding-left: 4px;

    &:hover {
      box-sizing: border-box;
      border: 1px solid $COLOR_LIGHT_GREY1;
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-form-item-control {
      line-height: 1.5;
    }
  }

  .ant-form-item-control-wrapper {
    .ant-form-item-control {
      line-height: 21px;
    }
  }
}

.context-menu-cell {
  height: 1px;
  padding: 0 !important;
}

.ant-table-tbody {
  height: 100%;
}

.ant-table-row {
  height: 100%;
}

.ant-table-row:hover > td {
  background-color: $COLOR_ORANGE_SOFT !important;
}

.ant-table-expanded-row {

}

.ant-table-expanded-row > td:last-child {
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.15);
}

.ant-table-expanded-row > td {
  background: $COLOR_WHITE;
}

.empties-yard-request-list {
  .ant-table-placeholder {
    display: none;
  }
}

.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background-clip: padding-box;
}

.shift-tal-list-table {
  .ant-table-small {
    border: none;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 6px 6px;
  }

  table {
    font-size: 11px;

    .ant-table-tbody {
      border-left: none;
      border-right: none;
    }
  }

  th {
    background-color: transparent !important;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }
}

.tal-table {
  .divider {
    border-bottom: 1px solid $COLOR_DUST_GREY;
  }

  .vertical-divider {
    border-right: 1px solid $COLOR_DUST_GREY;
  }

  .tal-table-alignment {
    padding-left: 5px;
  }
}

.no-padding-td {
  height: 1px;
  padding: 0 !important;
}

.tos-square {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;

  .tos-square-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 4px;
  }

  .tos-square-section-middle {
    @extend .tos-square-section;
    padding: 0;

    .tos-square-section-number-middle {
      border-radius: 4px;
      border: 1px solid $COLOR_LIGHT_GREY1;
      padding: 4px;
    }
  }

  .bottom {
    margin-top: 4px;
  }

  .tos-square-section-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }

  .mismatch {
    background-color: #FEF7F6;
    color: $COLOR_RED;
    border-radius: 4px;
  }
}

.tos-square-side {
  display: flex;
  height: 100%;
  flex-direction: column;

  .tos-square-side-number {
    transform: rotate(90deg);
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $COLOR_LIGHTER_GREY3;
    font-size: 10px;
  }
}

.ATAOPS-timestamp {
  font-size: 9px;
}

.ATAOPS {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;

  .ATAOPS-timestamp-wrapper {
    position: relative;
    flex-grow: 1;

    .ATAOPS-timestamp {
      position: absolute;
      bottom: -14px;
      left: 0;
      font-size: 10px;
    }
  }
}

.special-containers {
  display: block;

  .ant-tag {
    display: inline;
  }
}

.row-status-default {
  border-left: 3px solid transparent;
}

.row-status-in-execution {
  border-color: $COLOR_GREEN;
}

.row-status-cancelled {
  border-color: $COLOR_RED_FADED;
  opacity: 0.25;
}

.row-status-executed {
  border-color: $COLOR_LIGHTER_GREY3;
  opacity: 0.25;
}

.row-status-paused {
  border-color: $COLOR_ORANGE;
}

.even-row {
  background: $COLOR_LIGHTER_GREY1;
}

.table-checkbox-aligment-fix {
  .ant-checkbox-wrapper {
    display: flex;
    justify-content: center;
  }
}

.gang-list-overview {
  table tbody tr td {
    padding: 2px 0 2px 0 !important;
  }

  table tbody tr:last-child {
    td {
      border: none
    }
  }
}

.bts-number-of-containers {
  vertical-align: top;
  padding-top: 20px !important;
}
