.contactInfo-card {
  background-color: $COLOR_LIGHTER_GREY1;
  margin: 8px;
}

.contactInfo-card-name {
  font-weight: bold;
  font-size: $FONT_SIZE_XS;
}

.contactInfo-card-available {
  color: $COLOR-GREEN;
}

.contactInfo-card-unavailable {
  color: $COLOR-RED;
}

.contact-detail-remarks {
  font-size: 11px;
}

.not-active-remarks {
  color: transparent;
}
