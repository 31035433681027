$elearning-popover-width: 350px;
$elearning-popover-width: 350px;
$elearning-switch: $COLOR_GREY;
$elearning-toggle-height: 44px;
$elearningbadge-border-color: white;
$elearningbadge-color: $COLOR_BLUE;
$elearningbadge-size: 1.8rem;


.elearningbadge {
  position:absolute;
  z-index: 1000;

  .elearningbadge__chest {
    animation: entry 0.5s 1;
    animation-timing-function: ease-out;
    background-color: transparentize($elearningbadge-color, 0.5);
    border-radius: $elearningbadge-size / 2;
    cursor: pointer;
    height: $elearningbadge-size;
    left: $elearningbadge-size / -2;
    overflow: hidden;
    position:absolute;
    top: $elearningbadge-size / -2;
    transition: background-color 1s ease-out;
    width: $elearningbadge-size;

    &:hover {
      background-color: $elearningbadge-color;
    }
  }

  .elearningbadge__content {
    color: $elearningbadge-border-color;
    display: inline-block;
    font-size: $elearningbadge-size * .45;
    font-weight: bolder;
    line-height: $elearningbadge-size * 1.05; // A question mark can be lowered a bit.
    margin:0;
    text-align: center;
    width: $elearningbadge-size;
  }
}

@keyframes entry {
  0% {
    background-color: transparentize($elearningbadge-color, 1)
  }
  100% {
    background-color: transparentize($elearningbadge-color, 0.5)
  }
}

.elearning__link {
  float: right !important;
  height: $elearning-toggle-height !important;
  padding-left: 0;
  padding-right: 0;
  border-bottom: none !important;

  a {
    margin-left: $elearning-toggle-height / 4;
    margin-right: $elearning-toggle-height / 4;
    color: $COLOR_DUST_GREY;
  }
}

.ant-popover-content {
  margin-top: 0.6rem;
}

.ant-popover-arrow {
  display: none;
}

.elearningbadge__popover {
  width: $elearning-popover-width;
  .popover__content {

  }
}

.elearning__placeholder {
  visibility: hidden;
}

.elearning__toggle {
  border: none !important;
  float: right;
  height: $elearning-toggle-height;

  .ant-switch {
    background-color: $COLOR_GREY;
  }

  .ant-switch-checked {
    background-color: $elearningbadge-color;
  }
}


