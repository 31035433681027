.last-updated__container {
  position: relative;
  display: inline-block;
}

.last-updated {
  z-index: 3;
  bottom: -20px;
  position: absolute;
  border-radius: 10px;
  background-color: $COLOR_LIGHT_GREEN;
  padding: 4px;
  font-size: $FONT_SIZE_XS;
  right: 0;
  color: $COLOR_GREEN;
}
