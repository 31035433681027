.timeline-time {
  font-size: 7px;
}

.stripe {
  fill: #DDD;
}

.timeline-visit {
  fill: #F6F6F6;
  stroke: #F6F6F6;
  stroke-width: 2;
  opacity: 0.25;
}

.timeline-visit-active {
  fill: #1B89CA;
  stroke: #1B89CA;
  opacity: 0.25;
  stroke-width: 2;
}

#timeline {
  fill: #DDD;
}

.timeline-visit-hover {
  fill: transparent;
}

.timeline-now {
  fill: $COLOR_RED;
}

.timeline-now-line {
  stroke: $COLOR_RED;
  stroke-dasharray: 4;
}

.timeline-visit-travel {
  stroke: $COLOR_LIGHTER_GREY3;
  stroke-dasharray: 3;
  fill: transparent;
  stroke-width: 2;
}

.timeline-visit-travel-psa {
  stroke: #5B9AC4;
  stroke-dasharray: 3;
  fill: transparent;
  stroke-width: 2;
}

.timeline-visit-psa {
  fill: #D5EAF6;
  stroke: #5B9AC4;
  stroke-width: 2;
}

.timeline-visit-active-psa {
  fill: #1B89CA;
  stroke: #1B89CA;
  stroke-width: 2;
}

.fill-blue {
  fill: $COLOR_BLUE;
}

.timeline-tal {
  font-size: 12px;
}

.timeline-quay {
   font-size: 10px;
}

.navchart-font {
  fill: $COLOR_TEXT;
}


.visit-status-in-execution {
  fill: $COLOR_GREEN;
  stroke: $COLOR_GREEN;
}

.visit-status-cancelled {
  fill: $COLOR_RED_FADED;
  stroke: $COLOR_RED_FADED;
}

.visit-status-executed {
  fill: $COLOR_LIGHTER_GREY3;
  stroke: $COLOR_GREY;
}

.visit-status-paused {
  fill: $COLOR_ORANGE;
  stroke: $COLOR_ORANGE;
}

.white-background {
  fill: $COLOR_WHITE;
  stroke: $COLOR_WHITE;
  stroke-width: 2;

}
