.ant-form {
  h3 {
    margin-bottom: $FORM_SPACING;
  }
}
.ant-form-item-children {
  .ant-btn-dashed {
    padding-right: 30px;
    margin-top: 0px;
  }
 }

.ant-form-vertical .ant-form-item {
  padding-bottom: 0px;
}

.form-dynamic-row {
  .ant-form-item {
    margin-bottom: $FORM_SPACING / 2;
  }
}

.has-error {
  .textarea.ant-input {
    border-color: #f5222d;
    box-shadow: none;
  }
}

.textarea.ant-input {
  padding: $CONTENT_SPACING / 2;
  border-radius: 5px;
  background: $COLOR_LIGHTER_GREY1;
  border: 1px solid $COLOR_LIGHT_GREY2;
  box-shadow: none;
}

.ant-time-picker-panel-input-wrap {
  max-width: none !important;
}

.ant-form-inline {
  .form-inline-label {
    .ant-form-item-label {
      text-align: left;
      display: block;
      font-weight: bold;
      line-height: 24px;
    }
  }
}

.bollard-form {
  .ant-form-item {
    margin-right: $COLLAPSE_SPACING / 2;
  }
}

.quay-length-input {
  width: 100%;
}

.ant-form-item-row {
  display: flex;
  flex-direction: column;
}
