.alert {
  border-radius: 17px;
}

.alert-error {
  @extend .alert;
  color: #EC5B55;
  border: 1px solid #EC5B55;
  background: #FEF7F6;
  font-weight: 500;
  min-width: fit-content;
  display: inline-flex !important;

  .anticon {
    color: #EC5B55;
  }
}
