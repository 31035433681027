.visit-detail-barge {
  text-transform: uppercase;
  font-size: $FONT_SIZE_M;
}

.visit-detail-title {
  font-weight: bold;
  font-size: $FONT_SIZE_M;
  color: $COLOR_MENU;
}

.visit-detail-updated {
  font-size: $FONT_SIZE_XS;
  color: $COLOR_DUST_GREY;
  padding-right: 5px;
}

.talList_editable_td {
  padding: 0;
}

.talList--editable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  padding-left: 4px;

  &:hover {
    box-sizing: border-box;
    border: 1px solid $COLOR_LIGHT_GREY1;
  }

  .ant-form-item-control {
    line-height: 1.5;
  }
}

.tal-table {
  .ant-form-item-control-wrapper {
    .ant-form-item-control {
      line-height: 21px;
    }

    input {
      width: 100px;
    }
  }
}

.visit-top-link:first-child {
  padding-left: 0;
}

.visit-top-link {
  color: $COLOR_BLACK;
  text-decoration: underline;
  background: none;
  border: none;
}

.visit-top-link:hover {
  transition: color .3s;
  color: $COLOR_ORANGE;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.approved-check-with-text {
  display: flex;
  align-items: center;

  .label {
    text-align: left;
    font-size: 12px;
  }

  &:first-child {
    margin-right: 10px;
  }
}

.top-actions-menu-wrapper {
  display: flex;
  justify-content: flex-end;
}

.visit-overview {
  thead tr, tbody tr {
    font-size: 11px;
  }
}

.ant-table-bordered .ant-table-tbody tr.container--after-cut-off {
  background-color: $COLOR_LIGHT_RED;
  border-left: 1px solid $COLOR_RED;
  > td {
    border-bottom: 1px solid $COLOR_RED;
    border-top: 1px solid $COLOR_RED;
    position: relative;
    &:last-child {
      border-right: 1px solid $COLOR_RED;
    }
    .container--after-cut-off__icon{
      position: relative;
      color: $COLOR_RED;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
