.relative {
  position: relative;
}

.pull-right {
  text-align: end;
}

.pull-left {
  text-align: start;
}

.uppercase {
  text-transform: uppercase;
}
