$PANEL_BACKGROUND: $COLOR_WHITE;

.collapse {
  border-radius: 5px;
  border: 1px solid $COLOR_LIGHT_GREY2;

  .panel {
    background: $PANEL_BACKGROUND;
  }
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 5px;
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 5px;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0 solid #d9d9d9;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  font-size: 12px;
  position: absolute;
  display: inline-block;
  line-height: 75px;
  vertical-align: top;
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  top: 0;
  left: 16px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow:before {
  display: inline-block;
  font-family: "anticon" !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  height: 75px;
}

.ant-collapse-content-box {
  overflow: auto;
  padding-top: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: $COLLAPSE_SPACING;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0;
}

.collapse-content-wrapper {
  overflow: hidden;
  box-sizing: border-box;
}

.collapse-error-table-header-timestamp {
  float: right;
  padding-right: 20px;
}

.collapse-header-title {
  margin-bottom: 0;
  height: 100%;
}

.collapse-remark-empty {
  text-align: center;
  height: 248px;
  line-height: 248px;
  color: $COLOR_DUST_GREY;
}

.collapse-error {
  @extend .collapse;
  width: 100%;

  .alert {
    display: inline-block;
    margin-left: $COLLAPSE_SPACING / 2;
  }

  .send-btn {
    margin-left: $COLLAPSE_SPACING / 2;
  }
}

.ant-collapse {
  font-family: "Roboto", sans-serif !important;
}

.ant-collapse-expand-icon {
  display: flex;
  align-self: center;
}

.section-full-width {
  width: 100%;
}
