.label {
  border-radius: 5px;
  background: $COLOR_LIGHT_GREY2;
  padding: 8px;
  font-size: 12px;
}

.label-red {
  @extend .label;
  background: #FFEFEE;
  color: $COLOR_RED;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  font-size: 18px;
  padding: $CONTENT_DP;

  .ant-select {
    width: 344px;
  }

  .ant-select-selection {
    color: $COLOR_RED !important;
    border-color: #ECD0CF;
  }

  .ant-select-arrow {
    color: $COLOR_RED !important;
  }
}

.label-mini {
  border-radius: 5px;
  background-color: $COLOR_GREY2;
  padding: 2px;
  border: 0;
  margin: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;
  overflow: hidden;

  &.red {
    background-color: #FFEFEE;
    color: $COLOR_RED;
  }
}
