.visit-filter {
  margin-bottom: $CONTENT_SPACING;

  .ant-form-item > .ant-form-item-label {
    text-align: left;
    display: block;
  }

  .ant-form-item-label {
    line-height: 25px;
    font-weight: bold;
  }

  .ant-form-item-label label:after {
    display: none;
  }

  .contains-error {
    top: 30px;
    font-size: $FONT_SIZE_S;
  }

  .search {
    width: 300px;
  }

  .quay-selector {
    width: 100%;

    .ant-form-item-control-wrapper {
      display: block;
    }
  }

  .ant-input {
    padding: 15px;
    height: auto;
  }

  .ant-select-selection {
    padding: 12px;
    height: auto;
  }

  .ant-calendar-range-picker-input {
    text-align: left;
  }

  .ant-calendar-range-picker-separator {
    right: 10px;
    position: relative;
  }

  .ant-form-item {
    margin-right: $CONTENT_SPACING !important;
  }
}

.visit-table-pagination {
  margin-top: $CONTENT_SPACING / 2;
  overflow: auto;

  .pagination {
    float: right;
  }
}

.visit-barge {
  text-overflow: ellipsis;
  width: 100%;
}

.visit-barge-alignment {
  display: inline-block;
}

.visit-barge-operator {
  color: #aaa;
  font-size: 11px;
  width: 100%;
  display: inline-block;
}

.info-bar {
  background: $COLOR_WHITE;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid $COLOR_LIGHT_GREY1;

  .infobar-section {
    padding: 11px 29px 11px 29px;
    min-height: 160px;
    height: 100%;
  }

  .divided {
    border-right: 1px solid $COLOR_LIGHT_GREY1;
  }
}

.visit-inline-warnings {
  display: block;
  margin: 15px;

  .special-containers {
    margin-left: 10px;
    display: inline;

    .time-not-in-geofence {
      display: inline;
    }

    .anticon {
      margin-left: 2px;
      margin-right: 2px;
    }

    i:first-child {
      margin-left: 0;
    }

    i {
      cursor: pointer;
    }
  }
}

.icon-remarks-red {
  color: $COLOR_RED;
}

.icon-remarks-orange {
  color: $COLOR_ORANGE;
}

.icon-remarks-blue {
  color: $COLOR_BLUE;
}

.bts-remark {
  margin-bottom: 10px;
  background: rgba($COLOR_LIGHT_GREY1, 0.2);

  h2 {
    color: $COLOR_BLACK;
  }
}

.time-not-in-geofence {
  color: $COLOR_RED;
  
  .anticon {
    margin-left: 6px;
  }
}

.visit-list-table-time-okay {
  font-size: 10px;
}

.tooltip-list {
  margin-top: 5px;
  margin-bottom: 7px;
}

.multiple-planned-visits {
  color: $COLOR_RED;
}
