// @flow
/* skeleton
 * All constants used for styling are here
 */

$GUTTER: 16px;

$SIZE_XXS: 5px;
$SIZE_XS: 10px;
$SIZE_S: 15px;
$SIZE_M: 20px;
$SIZE_L: 25px;
$SIZE_XL: 30px;

$COLOR_DARK_GREEN: #237036;
$COLOR_GREEN: #31AD4F;
$COLOR_LIGHT_GREEN: #E0F9E6;

$COLOR_RED: #EC5B55;
$COLOR_LIGHT_RED: #FEF7F6;
$COLOR_RED_FADED: #F6D9D7;
$COLOR_ORANGE: #FBA435;
$COLOR_ORANGE_SOFT: #FFFBF0;

$COLOR_BLACK: #000;
$COLOR_DARKER_GREY: #222;
$COLOR_DARK_GREY: #333;
$COLOR_GREY: #888;
$COLOR_GREY2: #EEEEEE;

$COLOR_LIGHT_GREY1: #DDD;
$COLOR_LIGHT_GREY2: #E8E8E8;
$COLOR_LIGHTER_GREY1: #FAFAFA;
$COLOR_LIGHTER_GREY2: #F6F6F6;
$COLOR_LIGHTER_GREY3: #CCCCCC;
$COLOR_DUST_GREY: #999999;

$COLOR_WHITE: #FFF;

$COLOR_MENU: #444444;
$COLOR_TEXT: #555555;

$COLOR_BLUE: #1B89CA;
$COLOR_LIGHT_BLUE: #E7F2FF;

// TEXT

$FONT_SIZE_XXS: 10px;
$FONT_SIZE_XS: 12px;
$FONT_SIZE_S: 14px;
$FONT_SIZE_M: 16px;
$FONT_SIZE_L: 20px;
$FONT_SIZE_XL: 25px;

$FONT_WEIGHT_S: 300;
$FONT_WEIGHT_M: 400;
$FONT_WEIGHT_L: 500;
$FONT_WEIGHT_XL: 600;

$BOX_SHADOW: 0 2px 7px 0 rgba(0,0,0,0.15);

// LAYOUT

$CONTENT_SPACING: 36px; // space between elements (padding & margin)
$FORM_SPACING: 24px;
$CRUD_HEADER_HEIGHT: 50px;
$COLLAPSE_SPACING: 24px;
$CONTENT_DP: 10px;
$CONTENT_MAX_WIDTH: 1840px;

$COLOR_PRIMARY: $COLOR_ORANGE;
$COLOR_SECONDARY: rgba(37,184,177,0.25);
