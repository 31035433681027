.tag-rounded {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-tag {
  border-radius: 14px;
  text-align: center;
  pointer-events: none;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 22px;
  border: 0;
  background: $COLOR_LIGHT_GREY2;
}

.ant-tag.circle {
  height: 22px;
  min-width: 22px;
  text-align: center;
}

.ant-tag-green {
  color: $COLOR_GREEN;
  background: $COLOR_LIGHT_GREEN;
}

.ant-tag-red {
  color: $COLOR_RED;
  background: $COLOR_LIGHT_RED;

  .exclamation-icon {
    font-size: 14px !important;
  }
}

.ant-tag-blue {
  background: $COLOR_LIGHT_BLUE;
  color: $COLOR_BLUE;
}

.icon-reefer {
  color: $COLOR_BLUE;
}

.icon-imdg {
  color: $COLOR_ORANGE;
}

.icon-oversize {
  color: $COLOR_GREEN;
}

.tag-bright {
  background: $COLOR_ORANGE;
  color: $COLOR_WHITE;
}

.gang-color-tag {
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -1px;

  span {
    display: block;
    width: 100%;
    height: 100%;
  }
}
