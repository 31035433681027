.remark {
  padding: $CONTENT_SPACING / 2;
  border: 1px solid $COLOR_LIGHT_GREY2;

  .message {
    margin-bottom: $CONTENT_SPACING / 2;
  }

  .meta {
    color: $COLOR_DUST_GREY;
    font-size: 12px;
  }
}

.remark-input {
  padding: $CONTENT_SPACING / 2;
  border: 1px solid $COLOR_LIGHT_GREY2;
  border-radius: 5px;
  background: $COLOR_LIGHTER_GREY1;
  outline: none;
  box-shadow: none;
  resize: none;
}

.remark-list {
  .remark {
    margin-bottom: $COLLAPSE_SPACING / 2;
  }

  .remark:last-child {
    margin-bottom: 0;
  }
}
