.custom-panel {
  background: $COLOR_WHITE;
  border-radius: 5px;
  border: 1px solid $COLOR_LIGHT_GREY2;
  padding-bottom: $COLLAPSE_SPACING;
  padding-left: $COLLAPSE_SPACING;
  padding-right: $COLLAPSE_SPACING;
  overflow: hidden;
  width: 100%;

  .ant-table-wrapper {
    width: 100%
  }
}

.tal-panel {
  @extend .custom-panel;
  min-height: 528px;
  padding-bottom: 0;
}

.map-panel {
  @extend .tal-panel;
  padding: 0;
}

.no-data-panel {
  @extend .tal-panel;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;

  .no-data-panel-text {
    border-radius: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    // background-color: rgba(246, 246, 246, 0.6);
    z-index: 10;
    font-size: 14px;

    .no-data-label {
      padding: $CONTENT_SPACING;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.125);
    }
  }

  .map-panel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    filter: blur(3px);
  }
}
