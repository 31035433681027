.text-normal {
  color: $COLOR_TEXT;
}

.text-green {
  color: $COLOR_GREEN;
}

.text-orange {
  color: $COLOR_ORANGE;
}

.text-red {
  color: $COLOR_RED;
}

.text-black {
  color: $COLOR_BLACK;
}

.text-dust {
  color: $COLOR_DUST_GREY;
}

.text-menu {
  color: $COLOR_MENU;
}

.text-underline {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-capitalized {
  text-transform: capitalize;
}

.text-disabled {
  color: $COLOR_DUST_GREY;
}

.text-subtitle {
  color: $COLOR_DUST_GREY;
  font-size: 14px;
}

.text-extra-small {
  font-size: $FONT_SIZE_XS;
}

.text-extra-extra-small {
  font-size: $FONT_SIZE_XXS;
}

.text-exploding-out {
  font-size: 21px;
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.in-content-title {
  margin-top: 24px;
  margin-bottom: 15px !important;
  width: 100%;
}

.title-with-history {
  margin-bottom: 0;
}

.human-text-history {
  color: $COLOR_GREY;
  font-size: 10px;
  font-weight: 300;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.total-hours-ago {
  display: block;
  font-size: 9px;
}

.gangs-right-top {
  float: right;
  margin-top: -20px;
}
