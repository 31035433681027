$SIDER_WIDTH: 240px;

.ant-layout-header {
  background: $COLOR_DARKER_GREY;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  .ant-menu.ant-menu-dark, .ant-menu-sub {
    background: $COLOR_DARKER_GREY;
  }
  .top-menu {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    justify-content: space-between;

    .logo {
      width: $SIDER_WIDTH;
      min-width: fit-content;
      a {
        display: inline-block;
        position: relative;
        bottom: 27px;
        background: $COLOR_WHITE;
        color: $COLOR_BLACK;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 0.89px;
        padding-bottom: 1px;
        text-decoration: none;
      }
      .triangle {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 64px 40px 0 0;
        background-color: $COLOR_DARKER_GREY;
        border-color: $COLOR_WHITE transparent transparent transparent;
      }
    }
    .ant-menu-horizontal {
      width: calc(100vw - #{$SIDER_WIDTH});
      font-family: "Montserrat", sans-serif;
    }

    .ant-menu-item-disabled {
      display: none;
    }
  }
  .ant-menu.ant-menu-dark {
    .ant-menu-item {
      padding-bottom: 7px;
    }

    .username {
      margin-right: 8px;
    }

    .usermenu {
      float: right;
      padding-top: 9px;
      padding-bottom: 7px;
      padding-right: 10px;

      .ant-menu-submenu-arrow {
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: absolute;
        top: 52%;
        width: 10px;
        color: $COLOR_WHITE;
      }
      .ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after {
        content: '';
        position: absolute;
        vertical-align: baseline;
        background: $COLOR_WHITE;
        width: 6px;
        height: 1.5px;
        border-radius: 2px;
        transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .ant-menu-submenu-arrow:before {
        transform: rotate(-45deg) translateX(2px);
      }
      .ant-menu-submenu-arrow:after {
        transform: rotate(45deg) translateX(-2px);
      }
    }
    .ant-menu-item-selected {
      background: inherit;
      border-bottom: 2px solid $COLOR_WHITE;
    }
  }
}

.usermenu ul.ant-menu-vertical {
  background: $COLOR_DARKER_GREY;
}

.ant-layout-sider {
  background: $COLOR_LIGHTER_GREY1;
  width: $SIDER_WIDTH !important;
  min-width: $SIDER_WIDTH !important;
  max-width: $SIDER_WIDTH !important;
  flex: 0 0 $SIDER_WIDTH !important;
  .ant-menu {
    background: $COLOR_LIGHTER_GREY1;
    color: $COLOR_MENU;

    .ant-menu-submenu-title:hover {
      color: #AAA;
    }
    .ant-menu-item-selected {
      background: inherit;
      & > a {
        font-weight: 500 !important;
        color: $COLOR_ORANGE !important;
      }
    }
    .ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after {
      border-right: 2px solid $COLOR_ORANGE;
    }

    .ant-menu-submenu {
      &.ant-menu-submenu-open {
        background: $COLOR_LIGHTER_GREY1;
        color: $COLOR_MENU;

        .ant-menu-sub {
          background: $COLOR_LIGHTER_GREY1;
          box-shadow: none;
        }
      }
      .ant-menu-item > a {
        text-transform: capitalize;
        font-weight: 300;
        color: $COLOR_MENU;
        font-size: $FONT_SIZE_S;

        &:hover {
          color: #AAA;
        }
      }
    }
  }

  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #222222 !important;
  }

  .ant-menu-inline .ant-menu-submenu-title, .ant-menu-item {
    box-shadow: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.ant-menu-submenu {
  border-style: none none inset none;
  border-width: 1px;
  border-color: $COLOR_LIGHT_GREY1;
}

.ant-layout-sider {
  border-right: 1px solid $COLOR_LIGHT_GREY1;
}

.ant-menu-inline {
  border-right: none;
}

.context-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.context-menu {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

    .dot {
      min-height: 4px;
      height: 4px;
      width: 4px;
      min-width: 4px;
      background-color: #D8D8D8;
      border-radius: 100px;
      margin-bottom: 4px;
    }

    .dot:last-child {
      margin-bottom: 0;
    }
}

.context-menu:hover {
  .dot {
    background-color: $COLOR_TEXT;
  }
}

.context-menu.active {
  background-color: $COLOR_LIGHTER_GREY1;

  .dot {
    background-color: $COLOR_TEXT;
  }
}

.top-menu-left {
  width: 70% !important;
}

.top-menu-right {
  display: flex;
  align-items: baseline;
  width: fit-content !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}
